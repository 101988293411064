import React, { useState, useContext, useEffect, useMemo } from 'react'
import Store from 'store'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import Button from '../../general/button'
import Loading from '../../../components/general/loading'
import { SvgBackArrow } from '../../../components/general/icons'
import ideaStyles from '../../jobs/idea/styles.module.css'
import styles from './styles.module.css'
import { SvgRemoveIcon } from '../../../components/general/icons'
import * as yup from 'yup'
import organizationsResource from 'resources/organizations'
import { toast } from 'react-toastify';
import tilesStyles from '../../dashboard/tiles.module.css'
import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import Config from 'utils/config';
import { formatCurrency } from 'utils/formatters'
import Discount from 'components/general/discount'
import useSubscriptionMetadata from 'utils/useSubscriptionMetadata'
import { Link } from 'react-router-dom'
import useMasterCommandCenterLink from 'utils/useMasterCommandCenterLink'
import { intervalAdverb, toSentence } from 'utils/strings'
import quote1Src from 'images/quote-rob-schwartz.jpg'
import quote2Src from 'images/quote-teri-sun.jpg'
import quote3Src from 'images/quote-david-baldwin.jpg'
import { useSubscribeModal } from './subscribe_modal'
import { useNewCardInfoModal } from '../payments/new_card_info_modal'

const BasicPlanTile = ({ organization, setOrganization }) => {
  const {
    subscription,
    maxCommandCentersAllowed: commandCenters,
    maxProjectsAllowed: projects,
    paymentInfoDescriptor,
  } = useSubscriptionMetadata(organization)

  const { openModal, ModalComponent: CreditCardModal } = useNewCardInfoModal({
    onCompleted: ({ organization }) => { setOrganization(organization) },
  })

  return (<>
    <div className={tilesStyles.job_header}>
      Current Plan: {subscription.price.nickname}
    </div>
    <div className={styles.tileContent}>
      Full functionality<br />
      {commandCenters && <>
         Limited to {commandCenters} Command {pluralize('Center', commandCenters)}<br />
      </>}
      {projects ? <>
         Limited to {projects} {pluralize('project', projects)}
      </> : <>
         Unlimited projects
      </>}
      <br />
      {subscription.cancel_at_period_end ? <>
        Expires: {moment(subscription.cancel_at * 1000).format("MMMM Do, YYYY")}
      </> : <>
        Renews: {moment(subscription.current_period_end * 1000).format("MMMM Do, YYYY")}
      </>}
      {!subscription.metadata.isTrial() && <>
        <br />
        Account: {paymentInfoDescriptor}

        <button className="btn btn-primary btn-slim d-block" type="button" onClick={openModal}>
          Update Billing Info
        </button>
        <CreditCardModal />
      </>}
    </div>
  </>)
}

const AnnualTieredTile = ({ organization, setOrganization }) => {
  const {
    subscription,
    currentCommandCenters,
    currentTier,
    nextTier,
    commandCentersToUpgradeTier,
    activeDiscount,
    discounter,
    paymentInfoDescriptor,
  } = useSubscriptionMetadata(organization)

  const { openModal, ModalComponent: CreditCardModal } = useNewCardInfoModal({
    onCompleted: ({ organization }) => { setOrganization(organization) },
  })

  const { plan, price, discount } = subscription
  const { LinkComponent: MasterCommandCenterLink } = useMasterCommandCenterLink()

  // discount applied and remaining uses
  return (<>
    <div className={tilesStyles.job_header}>
      My Plan: {subscription.price.nickname}
    </div>
    <div className={styles.tileContent}>
      <span style={{verticalAlign: 'bottom'}}>Command Centers:</span>
      <span className={styles.yellowBubble}>
        {currentCommandCenters}
      </span>
      <br />
      Annual cost per Command Center: {formatCurrency(currentTier.unit_amount / 100)}
      <br />
      {activeDiscount && <>
        Active discount: <span style={{color: '#009245'}}>{activeDiscount.name}</span><br />
        Your annual cost per Command Center: {formatCurrency(discounter(currentTier.unit_amount) / 100)}
        <br />
      </>}
      {subscription.cancel_at_period_end ? <>
        Expires: {moment(subscription.cancel_at * 1000).format("MMMM Do")}
      </> : <>
        Renews: {moment(subscription.current_period_end * 1000).format("MMMM Do, YYYY")}
      </>}
      <br />

      {nextTier && <span style={{color: '#009245'}}>
        <MasterCommandCenterLink style={{ color: 'inherit' }}>
          Add {commandCentersToUpgradeTier} more Command {pluralize('Center', commandCentersToUpgradeTier)}
        </MasterCommandCenterLink> for next price break
      </span>}
      <br />
      Account: {paymentInfoDescriptor}

      <button className="btn btn-primary btn-slim d-block" type="button" onClick={openModal}>
        Update Billing Info
      </button>
      <CreditCardModal />
    </div>
  </>)
}

const PRICE_TILE_COMPONENTS = {
  // trial
  price_trial_v1: BasicPlanTile,
  price_trial_v2: BasicPlanTile,
  price_trial_v3: BasicPlanTile,
  // payg
  price_pay_as_you_go_v1: BasicPlanTile,
  // monthly
  price_monthly_v2: BasicPlanTile,
  price_monthly_v3: BasicPlanTile,
  // annual
  price_annual_v1: AnnualTieredTile,
  price_annual_v2: BasicPlanTile,
  price_annual_v3: BasicPlanTile,
}

const planUpgradeLabel = (plan) => {
  const { interval } = plan.recurring

  // if we have a discount, don't claim that the price is recurring. just show
  // the price for the first purchase and use the discount to explain why
  if(plan.discount) {
    const advertisedAmount = plan.unit_amount - plan.discount.amount_off
    const reason = plan.discount.metadata?.ACCOUNT_STATUS_OFFER_DESCRIPTION ?
      plan.discount.metadata?.ACCOUNT_STATUS_OFFER_DESCRIPTION : `(${plan.discount.name})`
    return `${intervalAdverb(interval)} at ${formatCurrency(advertisedAmount / 100)} ${reason}`
  }

  // if no, discount, simply show price per interval
  return `${intervalAdverb(interval)} at ${formatCurrency(plan.unit_amount / 100)}/${interval}`
}

const UpgradeTile = ({ currentSubscription, plans, disableActions, isUpgradingState, setOrganization }) => {
  const { openModal, ModalComponent } = useSubscribeModal({
    plans,
    currentSubscription,
    onSubscribeComplete: setOrganization
  })

  const projects = plans[0].metadata?.NUMBER_OF_PROJECTS

  return (<>
    <div className={tilesStyles.job_header}>
      Subscribe
    </div>
    <div className={styles.tileContent}>
      {currentSubscription?.metadata?.isTrial() && <div className="mb-2">
        When you subscribe to Ideasicle X, your Trial project will be automatically archived and accessible from your new Command Center.
      </div>}
      <strong>Subscription includes:</strong><br />
      Full access to Ideasicle X<br />
      {projects ? <>
         Limited to {projects} {pluralize('project', projects)}
      </> : <>
         Unlimited projects
      </>}
      <br />
      Free upgrade to Master Command Center (optional) <br />

      {plans.length > 1 && <>Plan options: </>}
      {toSentence(
        plans.map(p => planUpgradeLabel(p)),
        'or'
      )}
      <br />
      Click "Subscribe" to choose your plan and get started.

      <div className="mt-3 mb-5 pb-5">
        <button disabled={disableActions} className="btn btn-primary btn-slim m-auto" type="button" onClick={openModal}>
          Subscribe
        </button>
        <ModalComponent isSubmittingState={isUpgradingState} />
      </div>
    </div>
  </>)
}

export default function ManageOrganization ({ history, match, location }) {
  const { state } = useContext(Store)
  const [loading, setLoading] = useState(true)
  const [organization, setOrganization] = useState()
  const isUpgradingState = useState(false)
  const disableActions = loading

  const [_, forceRender] = useState(undefined)

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await organizationsResource.showCurrent()
        setOrganization(response.data)
      } catch(error) {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      } finally {
        setLoading(false)
      }
    }
    fetchOrganization()
  }, [])

  const navigateToDashboard = () => history.push('/')

  const PlanTile = useMemo(
    () => organization && PRICE_TILE_COMPONENTS[organization?.subscription?.price?.lookup_key],
  [organization?.subscription?.price?.lookup_key])

  const {
    maxCommandCentersAllowed,
    maxProjectsAllowed,
    multipleCommandCentersAllowed,
    currentCommandCenters,
    currentTier
  } = useSubscriptionMetadata(organization)

  const annualTierPlan = useMemo(
    () => [organization?.offer_plan, organization?.subscription?.plan].find(p => p && p.tiers)
  , [organization])

  const { LinkComponent: MasterCommandCenterLink } = useMasterCommandCenterLink()

  return (
    <Container>
      { loading && <Loading /> }
      { !loading && <>
        <div className={ideaStyles.back} onClick={navigateToDashboard}>
          <SvgBackArrow /> <span>Back to Dashboard</span>
        </div>
        <Row className={styles.hero}>
            <Col md={12} xs={12}>
            <p className={styles.header}>
                {organization.name} Account Status
            </p>
            </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} xs={12}>
            {PlanTile && <div className={classnames("p-2 mb-2", styles.subscriptionTile)}>
              <PlanTile organization={organization} setOrganization={setOrganization} />
            </div>}
            {organization?.offer_plans && <div className={classnames("p-2 mt-3 mb-2", styles.subscriptionTile)}>
              <UpgradeTile
                currentSubscription={organization.subscription}
                plans={organization.offer_plans}
                isUpgradingState={isUpgradingState}
                setOrganization={setOrganization}
                disableActions={disableActions}
              />
            </div>}
            {organization.users && organization.users.length > 1 && <div className={classnames("p-2 mt-3 mb-2", styles.subscriptionTile)} style={{border: '1px solid black'}}>
              <div className={tilesStyles.job_header}>
                Current Command Centers
              </div>
                <table className={styles.jobsTable} style={{ marginBottom: 'initial' }}>
                    <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {organization.users && organization.users.map((member) => {
                        return (
                          <tr key={member.id} className={styles.hover_row}>
                            <td>{member.first_name} {member.last_name}</td>
                            <td style={{maxWidth: 280, paddingRight: 10}}>
                              <a href={`mailto:${member.email}`} title={member.email} style={{ textOverflow: 'ellipsis', overflowX: 'hidden', display: 'block'}}>
                                {member.email}
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                </table>
                <div className="mt-2">
                  {multipleCommandCentersAllowed && <small>
                    To add additional Command Centers, visit your <MasterCommandCenterLink>Master Command Center</MasterCommandCenterLink>.
                  </small>}
                </div>
            </div>}
          </Col>
          {organization?.subscription?.metadata?.isTrial() && <Col lg={3} xs={12}>
            <img src={quote1Src} className="w-75" />
            <img src={quote2Src} className="w-75" />
            <img src={quote3Src} className="w-75" />
          </Col>}
          {annualTierPlan && <Col lg={6} xs={12}>
            <table className={styles.tierTable}>
              <thead>
                <th colspan={3} className={styles.tierTableHeader}>
                  <h2>Annual Plan Price Breaks</h2>
                </th>
              </thead>
              <tr className={styles.tierTableSubheader}>
                <td>Number Of Command Centers</td>
                <td>Annual Price Per Command Center</td>
                <td>Price Per Month Per Command Center</td>
              </tr>
              {annualTierPlan.tiers.map((tier,index) => {

                const prevTier = annualTierPlan.tiers[index - 1]
                const nextTier = annualTierPlan.tiers[index + 1]

                let tierRange = ''
                if(prevTier) { tierRange += prevTier.up_to + 1 }
                if(prevTier && tier.up_to) { tierRange += '-' }
                if(tier.up_to) { tierRange += tier.up_to }
                if(!nextTier) { tierRange += '+' }
                // tiers should list out 1, 2-10, 11+

                const isCurrentTier = annualTierPlan === organization?.subscription?.plan && tier === currentTier
                return (
                  <tr>
                    <td>
                      { isCurrentTier && <span style={{fontSize: 32, color: 'rgb(0, 146, 69)'}}>✓</span>}
                      {tierRange}
                    </td>
                    <td>{formatCurrency(tier.unit_amount / 100)}</td>
                    <td>{formatCurrency(tier.unit_amount / 100 / 12)}</td>
                  </tr>
                )
              })}
            </table>
          </Col>}
        </Row>
      </>}
    </Container>
  )
}
