import moment from 'moment'

export const daysFromNow = (numberOfDays) => {
  const date = new Date()
  date.setDate(date.getDate() + numberOfDays)
  return date
}


export const daysFromNowV2 = (numberOfDays) => {
  const date = new Date()
  date.setDate(date.getDate() + numberOfDays)
  return moment(date).format("MM/DD/YYYY")
}
