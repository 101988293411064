import React, { Fragment, useState, useEffect, useCallback, useContext, useMemo } from 'react'
import useAttribute from '../../../utils/useAttribute'
import { daysFromNow, daysFromNowV2 } from '../../../utils/date'
import styles from './styles.module.css'
import Button from '../../general/button'
import CurrencyInput from 'react-currency-input';
import { moneyConverter } from 'components/jobs/create'
import * as ExpertInviteStep from './expert_invite_step'
import  { ExpertCard } from './expert_invite_step.jsx'
import * as VitalsStep from './vitals_step'
import jobsResource from '../../../resources/jobs'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import * as routes from 'constants/routes'
import Config from 'utils/config'
import moment from 'moment'
import {Helmet} from 'react-helmet'
import ideaStyles from '../idea/styles.module.css'
import { SvgBackArrow } from '../../../components/general/icons'
import classNames from '../../../utils/classNames'
import pluralize from 'pluralize'
import Discount from 'components/general/discount'
import BudgetModal from './budget_modal'
import Store from 'store'
import useRoleRequired from 'utils/useRoleRequired'

const STEPS = [VitalsStep, ExpertInviteStep]

const dateConverter = (rawValue) => new Date(`${rawValue} 00:00`)
const intConverter = (rawValue) => rawValue ? parseInt(rawValue) : 0

export default function JobBuilderStep1Step2 ({ history, match }) {
  useRoleRequired('customer')

  const [ currentStep, setCurrentStep ] = useState(1)
  const [_, forceRender] = useState(undefined)

  const [expertBudget, setExpertBudget] = useState(null)
  const [saving, setSaving] = useState(false)
  const [invitedTeam, setInvitedTeam] = useState(false)
  const [inviteModal, setInviteModal] = useState(false)
  const [needsFreelancerBudgets, setNeedsFreelancerBudgets] = useState(false)

  // Cannot start on step 2 as it needs state from step 1
  // Step 1 and step 2 are really the same overall component even though the URL changes
  useEffect(() => {
    if(match.path === routes.JOB_BUILDER_STEP_2_FREELANCERS) {
      history.replace(routes.JOB_BUILDER_STEP_1_VITALS)
    }
  }, [])

  const MONEY_ATTRIBUTES = ['customized_budget', 'per_expert_budget']

  /* Step 1 */
  const attributes = {}
  attributes.title = useAttribute('title', '', forceRender)
  attributes.mission = useAttribute('mission', '', forceRender)
  attributes.startDate = useAttribute('start_date', daysFromNowV2(1), forceRender)
  attributes.endDate = useAttribute('end_date', daysFromNowV2(8), forceRender)

  attributes.numberOfExperts = useAttribute('number_of_experts', 4, forceRender, {
    change: (value) => { const newValue = intConverter(value); return Math.min(newValue, 50) }, blur: intConverter
  })
  attributes.customizedBudget = useAttribute('customized_budget', 6000.00, forceRender, {
    change: moneyConverter, blur: moneyConverter })
  attributes.perExpertBudget = useAttribute('per_expert_budget', 1500.00, forceRender, {
    change: (value) => { const newValue = moneyConverter(value); return Math.min(newValue, 50.0 * 1000) }
  })
  attributes.paymentType = useAttribute('payment_type', 'equal', forceRender)

  /* Step 3 */
  attributes.myTeamIds = useAttribute('expert_ids', [], forceRender)

  attributes.myOwnFreelancerEmails = useAttribute('invite_freelancer_emails', [], forceRender, {
    focus: (value) => value.join(', '),
    blur: (rawValue) => rawValue.split(/\s*,\s*/).filter((email) => email)
  })
  attributes.myOwnEmployeeEmails = useAttribute('invite_employee_emails', [], forceRender, {
    focus: (value) => value.join(', '),
    blur: (rawValue) => rawValue.split(/\s*,\s*/).filter((email) => email)
  })

  const handleShow = () => setInviteModal(true)

  const [expertBudgetsById, setExpertBudgetsById] = useState({})
  const [expertBudgetsByEmail, setExpertBudgetsByEmail] = useState({})

  const calculateExpertBudget = () => {
    if(attributes.paymentType.value === 'equal') {
      setExpertBudget(attributes.numberOfExperts.value * attributes.perExpertBudget.value)
    } else if (attributes.paymentType.value === 'custom') {
      setExpertBudget(attributes.customizedBudget.value)
    } else if (attributes.paymentType.value === 'none') {
      setExpertBudget(0.0)
    }
  }

  useEffect(() => {
    calculateExpertBudget()
  }, [attributes.numberOfExperts.value, attributes.customizedBudget.value, attributes.perExpertBudget.value, attributes.paymentType.value])


  const handleInputChange = (attribute) => (
    (event) => {
      event.persist()
      attribute.setter(event.target.value)
    }
  )

  const handleBackButton = () => {
    if (currentStep < 2) return
    window.scrollTo(0,0)
    setCurrentStep(currentStep - 1)
    history.goBack()
  }

  const currentStepModule = STEPS[currentStep - 1]

  const handleStep1Next = (event) => {
    event.preventDefault()
    if (currentStep > STEPS.length) return
    if (currentStepModule.validate(attributes)) {
      setCurrentStep(currentStep + 1)
      window.scrollTo(0,0)
      history.push(routes.JOB_BUILDER_STEP_2_FREELANCERS)
    }
  }

  const handleSubmitButton = async (event, { individual }) => {
    event.preventDefault()

    const job = Object.values(attributes).reduce((buffer, attribute) => Object.assign(buffer, {[attribute.label]: attribute.value}), {})
    MONEY_ATTRIBUTES.forEach((attribute) => {
      job[`${attribute}_in_cents`] = job[attribute] * 100
      delete job[attribute]
    })
    job.start_date = moment(job.start_date, "MM/DD/YYYY").toDate()
    job.end_date = moment(job.end_date, "MM/DD/YYYY").toDate()

    if(individual) {
      job.per_expert_budget_in_cents = 0
      job.expert_budgets_by_id = {}
      Object.keys(expertBudgetsById).forEach((expertId) => {
        job.expert_budgets_by_id[expertId] = parseInt(expertBudgetsById[expertId].replace(/[^\d]/g,'')) * 100
      })
      job.expert_budgets_by_email = {}
      Object.keys(expertBudgetsByEmail).forEach((email) => {
        job.expert_budgets_by_email[email] = parseInt(expertBudgetsByEmail[email].replace(/[^\d]/g,'')) * 100
      })
    }
    try {
      setSaving(true)
      const response = await jobsResource.create({data: {job}})
      toast.success("Congrats! Your invitations are on their way to your Freelancers.")
      history.replace({
        pathname: routes.INVITE_MORE(response.data.id),
        state: {
          job: response.data,
        }
      })
      history.push({
        pathname: routes.JOB_BUILDER_STEP_3_BRIEFING,
        state: {
          job: response.data,
        }
      })
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to create your job. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  const isSetBudgetAllowed = ((invitedTeam?.length || 0) + ExpertInviteStep.getValidEmails(attributes.myOwnEmployeeEmails).length + ExpertInviteStep.getValidEmails(attributes.myOwnFreelancerEmails).length) > 0

  const CurrentStepComponent = currentStepModule.component
  const CurrentStepTitleComponent = currentStepModule.title || (({ title }) => <Col><h1>{title.value}</h1></Col>)

  return (
    <Container>
      <Row>
        <Col>
          {currentStep == 2 && <Row>
            <Col>
              <div className={ideaStyles.back} onClick={handleBackButton}>
                <SvgBackArrow /> <span>Back to Step 1: Input Job Vitals</span>
              </div>
            </Col>
          </Row>}
          <Row>
            <Helmet>
              <title>Job Builder | Ideasicle X</title>
            </Helmet>
            <CurrentStepTitleComponent title={attributes.title} />
          </Row>

          <CurrentStepComponent attributes={attributes} suppressHeader={true} setInvitedTeam={setInvitedTeam} setNeedsFreelancerBudgets={setNeedsFreelancerBudgets} />

          <div className={styles.buttons}>
            {currentStep == 1 && <Button onClick={handleStep1Next}>Next</Button>}
            {currentStep == 2 && (
              <Button
                type="primary"
                disabled={saving || !isSetBudgetAllowed}
                onClick={(e)=> needsFreelancerBudgets ? handleShow() : handleSubmitButton(e, { individual: true })}
              >
                {needsFreelancerBudgets ? 'Set Budget' : 'Invite Team'}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {inviteModal &&
      <>
        <BudgetModal
          show={inviteModal}
          onClose={()=>setInviteModal(false)}
          attributes={attributes}
          invitedTeam={invitedTeam}
          expertBudgetsById={expertBudgetsById}
          expertBudgetsByEmail={expertBudgetsByEmail}
          expertBudget={expertBudget}
          onSubmitBudget={(e) => handleSubmitButton(e, { individual: false })}
          onSubmitIndividual={(e) => handleSubmitButton(e, { individual: true })}
          submitting={saving}
        />
      </>
      }

    </Container>
  )
}
