import React, { memo } from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import Logo from 'components/general/logo'
import Button from 'components/general/button'
import { CUSTOMER_ONBOARDING } from 'constants/routes'
import {Helmet} from "react-helmet";
import Avatar from '../../general/avatar'
export default memo(function UnauthenticatedLayout ({ children, signUp = true, cobrandOrganization }) {

  return (<>
    <Helmet>
      <body className="unauthenticated" />
    </Helmet>
    <div className={styles.main}>
      <div className={styles.left}>
        <div className={styles.leftPadding}>
          <Logo logoClass={styles.logo} />
          {cobrandOrganization && <Avatar record={cobrandOrganization}  style={{ float: 'right', marginTop: '7vh' }} />}

          <h1 className={styles.welcomeText}>Welcome!</h1>
          {signUp && <div className={styles.signIn}>
            <p className={styles.signUpText}>Don't have an account?</p>
            <div className={styles.signUpButton}>
              <Button to={'https://www.ideasiclex.com/pricing'} style={{borderColor: 'white', backgroundColor: 'black', color:'white'}}>Sign Up</Button>
            </div>
          </div>}
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.form}>
          {children}
        </div>
      </div>
    </div>
  </>)
})
