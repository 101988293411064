import React, { useState, useContext, useEffect, useLayoutEffect, useMemo } from 'react'
import Store from 'store'
import { Container, Row, Col, Collapse, Dropdown, Modal, Popover, OverlayTrigger, CloseButton } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import ideasResource from 'resources/ideas'
import Loading from 'components/general/loading'
import * as routes from 'constants/routes'
import styles from './styles.module.css'
import IdeaList from './idea_list'
import FileList from './file_list'
import { Route, Switch } from 'react-router-dom'
import ShowIdea from '../idea'
import EditIdea from '../idea/editIdea'
import CreateIdea from '../idea/create'
import EndJob from './end'
import MiniHeader from 'components/general/mini_header'
import moment from 'moment'
import { SvgTimeBarIndicator, SvgMessageIcon, SvgPdfIcon, SvgVideoIcon } from 'components/general/icons/index.jsx'
import { SvgPlusIcon, SvgRemoveIcon } from 'components/general/icons'
import { ExpertGrid, ExpertDropTarget, ExpertShortProfile } from '../create/expert_invite_step.jsx'
import Button from '../../general/button'
import { useDrop } from 'react-dnd'
import InstructionBox from '../create/instruction_box/index.jsx'
import { toast } from 'react-toastify';
import Uploader from './uploader'
import axios from 'axios'
import Config from 'utils/config'
import InviteMore from './invite_more.jsx'
import Icon from '@mdi/react';
import { mdiDownload, mdiArrowExpand, mdiArrowCollapse } from '@mdi/js';
import VideoPlayer from 'react-video-js-player';
import { SvgInfoIcon } from 'components/general/icons'
import './popovers.scss';
import autoTypeCast from 'auto-type-cast'
import ClientTeamSection  from './client_team'

const CancelToken = axios.CancelToken;
let searchCancelSource = null
import {
  isBrowser,
  isMobile
} from "react-device-detect";

export const ExpandCollapseSectionButton = ({ isExpanded, setExpanded }) => {
  const icon = isExpanded ? mdiArrowCollapse : mdiArrowExpand
  return (
    <Icon className="d-lg-none" path={icon} style={{height: 36, right: -5, padding: 4, top: -5, position: 'absolute'}} onClick={() => setExpanded(!isExpanded)} />
  )
}

const ExpertMessageButton = ({expert}) => {
  return Config.messaging ? (<div style={{position: 'absolute', bottom: 2, right: 6, cursor: 'pointer'}} >
      <SvgMessageIcon />
    </div>) : null
}

export const renderVideo = (job) => (
  <div key={job.briefing_video_url}>
    <VideoPlayer
      controls={true}
      width={650}
      height={263}
      src={job.briefing_video_url}
      poster={job.briefing_video_preview_url}
      hideControls={['playbackrates']}
    />
  </div>
)

export const renderFileList = (job, setJob, customerPerspective) => (
  <Col sm={12} className={styles.briefingUploads}>
    <div className={styles.fileListContainer}>
      <div>
        { job.briefing_document_url ?
          <div style={{ textAlign: 'center', padding: 8 }}>
            <a href={job.briefing_document_url} target="_blank" onClick={(e) => {e.preventDefault(); window.open(job.briefing_document_url, job.briefing_document_url, `height=${window.screen.height},width=600,screenX=${window.screen.width - 600},screenY=0`) }}>
              {job.briefing_document_preview_url && <img src={`${job.briefing_document_preview_url}`} />}
              {!job.briefing_document_preview_url && <Icon path={mdiDownload} style={{height: 32, width: 32}} /> }
            </a>
          </div>
          : customerPerspective ? <small className={styles.uploadStatus}>No Brief Uploaded Yet</small> : null
        }

      </div>
      <div>
        { job.other_documents.length > 0 ?
          <FileList files={job.other_documents} headerText={"Supporting Files"} />
          : customerPerspective ? <small className={styles.uploadStatus}>No Supporting Files Uploaded Yet</small> : null
        }
      </div>
      {customerPerspective && (
        <Row className="mx-0">
          <Col className="px-0" lg={4} sm={12}>
            <Uploader accept="video/*" job={job} setJob={setJob} icon={SvgVideoIcon} name="Video" attribute="briefing_video" />
          </Col>
          <Col className="px-0" lg={4} sm={12}>
            <Uploader accept="image/*, .pdf" job={job} setJob={setJob} icon={SvgPdfIcon} name="Brief" attribute="briefing_document" />
          </Col>
          <Col className="px-0" lg={4} sm={12}>
            <Uploader job={job} setJob={setJob} icon={SvgPdfIcon} name="Supporting Files" attribute="other_documents" />
          </Col>
        </Row>
      )}
    </div>
  </Col>
)

export const expertBorder = (expert) => {
  if(expert.expert_accepted === true) {
    return '2px solid #009245'
  } else if (expert.expert_accepted === false) {
    return '2px solid rgb(236,35,36)'
  } else {
    return '2px solid #ED9B2F'
  }
}

export default function ShowJob ({ history, match, location }) {
  const { state } = useContext(Store)
  const [job, setJob] = useState(location.state && autoTypeCast(location.state.job))
  const [loading, setLoading] = useState(!job)
  const [searching, setSearching] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchedIdeas, setSearchedIdeas] = useState([])
  const [team, setTeam] = useState([])
  const [saving, setSaving] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [filteredIdeas, setFilteredIdeas] = useState(location.state && location.state.job && location.state.job.ideas)
  const [selectedExpert, setSelectedExpert ] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [popoverPlacement, setPopoverPlacement] = useState("left")

  const handleClose = () => setDeleteModal(false);
  const handleShow = () => setDeleteModal(true);

  const [startJobModal, setStartJobModal] = useState(false)
  const [edittingTeam, setEdittingTeam] = useState(false)


  const addToTeam = (expert) => {
    if(teamIncludes(expert)) {
      return
    }
    setTeam(team.concat([expert]))
  }

  const removeFromTeam = (expert) => setTeam(team.filter((member) => member.id !== expert.id))
  const teamIncludes = (expert) => !!team.find((member) => member.id === expert.id)

  const fetchJob = async () => {
    try {
      const response = await jobsResource.show({ params: { id: match.params.jobId }})
      if ( response.data.status === 'created' && state.currentUser.role === 'expert' ) {
        toast.success("Congrats! You've accepted the Job. We're now waiting for the Organizer to confirm your participation.")
        history.push('/')
        return
      } else if ( (response.data.status === "closed" || response.data.status === "archived") && state.currentUser.role === "expert" ){
        toast.success("That job is now closed. Thanks for your participation!")
        history.push('/')
        return
      } else if ( Config.jobBuilderV3 && response.data.status === 'created' && state.currentUser.role === 'customer' ) {
        // allow Invite More
        if([routes.INVITE_MORE(response.data.id), routes.INVITE_MORE(response.data.slug)].includes(location.pathname)) {
          return
        }
        
        // this page will redirect to step 4 if appropraite
        history.replace({
          pathname: routes.JOB_BUILDER_STEP_3_BRIEFING,
          state: {
            job: response.data,
          }
        })
        return
      }
      setJob(response.data)
      setTeam(response.data.experts.filter((expert) => expert.chosen))

      setFilteredIdeas(response.data.ideas.sort((a,b) => b.updatedAtMoment() - a.updatedAtMoment()))
    } catch(err) {
      toast.warn("Uh oh! We couldn’t find that job")
      console.error('Error fetching job', err)
      history.push('/')
    } finally {
      setLoading(false)
    }
  }

  const [pdfDownloadTimeoutId, setPdfDownloadTimeoutId] = useState(false)

  const downloadPdf = async (showToast) => {
    try {
      setDownloading(true)
      const response = await jobsResource.pdf({ params: { id: job.slug } })
      if(response.data.zip_url) {
        window.location = response.data.zip_url
        setDownloading(false)
      } else {
        if(showToast) {
          toast.success("Please wait while we compile all the ideas into separate PDFs with the greatest of care and respect.")
        }
        setPdfDownloadTimeoutId(setTimeout(() => downloadPdf(false), 1500))
      }
    } catch(err) {
      console.log(err)
      toast.warn("Uh oh! We couldn’t download your Idea Stream Summary")
      setDownloading(false)
    }
  }

  useEffect(() => {
    return () => {
      if(pdfDownloadTimeoutId) {
        clearTimeout(pdfDownloadTimeoutId)
      }
    }
  }, [pdfDownloadTimeoutId])

  const [videoHelp, setVideoHelp] = useState(false)
  const [expertHelp, setExpertHelp] = useState(false)
  const [finalizeHelp, setFinalizeHelp] = useState(false)

  const showCallouts = useMemo( () => localStorage.getItem('seenJobCallouts'))

  useEffect(() => {
    if (showCallouts  !== 'true'){
      // page needs to render before popover can find its place
      setTimeout(() => {
        setVideoHelp(true)
        setExpertHelp(true)
        setFinalizeHelp(true)
      }, 500)
    }
    localStorage.setItem('seenJobCallouts', 'true')
    fetchJob()
  }, [])

  useLayoutEffect(() => {
    // Widths based on bootstrap and the column widths
    // defined in job_tiles.jsx
    function updatePopoverPlacement() {
      setPopoverPlacement(window.innerWidth >= 992 ? "left" : "top")
    }

    window.addEventListener('resize', updatePopoverPlacement);
    updatePopoverPlacement();

    return () => window.removeEventListener("resize", updatePopoverPlacement);
  }, [])

  const finalizeTeam = async () => {
    try {
      setSaving(true)
      const response = await jobsResource.finalizeTeam({ data: {
        expert_ids: team.map((expert) => expert.id),
      }, params: { id:  job.id }})
      setJob(response.data)
      if (edittingTeam){
        setEdittingTeam(false)
      }
      toast.success("Your team is locked in! We'll let them know.")
    } catch (error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to lock in your team. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  const startJob = async () => {
    try {
      setSaving(true)
      const response = await jobsResource.start({ params: { id:  job.id }})
      setJob(response.data)
      toast.success("Your Job has begun. Look for new Ideas soon!")
    } catch (error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to start the Job. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  function deleteJob(id){
    fetch(`/api/v1/jobs/${id}`,{
      method: 'DELETE'
    }).then(resp=>resp.json())
    .then(()=>{
      toast.success("Your Job has been closed successfully")
      history.push('/')
    }).catch(err=>console.log(err))
  }

  //stay in sync with search code in step3 expert search
  function handleSearchInputChange (event) {
    const value = event.target.value
    if (value.length > 2) {
      setSearching(true)
      setSearchLoading(true)
      if(searchCancelSource) { searchCancelSource.cancel('superceded by subsequent request') }
      searchCancelSource = CancelToken.source()
      const promise = ideasResource.search({cancelToken: searchCancelSource.token, params: {job_id: job.id, q: value}})
      promise.then((response) => {
        setSearchedIdeas(response.data)
        setSearchLoading(false)
      }).catch((error) => {
        if(!error.isCancel) {
          console.error(error)
          toast.warn("Uh oh! Something went wrong. Please try again shortly.")
        }
        setSearchLoading(false)
      })
    } else {
      setSearching(false)
      setSearchLoading(false)
    }
  }

  const navigateToCreateIdea = () => history.push(routes.CREATE_IDEA(job.slug))

  const awaitingLock = job && job.status === 'created' && !job.experts_locked
  const awaitingStart = job && job.status === 'created' && job.experts_locked
  const jobStarted = job && job.status !== 'created'
  const endSessionAllowed = job && job.status === 'active'
  const experts = job && job.expertTeam

  const expertPerspective = state.currentUser.role === 'expert'
  const customerPerspective = job?.can_edit
  const nonCustomerPerspective = !customerPerspective
  const viewCallout = customerPerspective && !jobStarted

  const timeOutOfRange = job && (moment() < moment(job.start_date) || moment(job.end_date) < moment())
  const totalHours = job && !timeOutOfRange && moment.duration(moment(job.end_date).diff(moment(job.start_date))).asHours()
  const hoursElapsed = job && !timeOutOfRange && moment.duration(moment().diff(moment(job.start_date))).asHours()
  const percentageElapsed = Math.min(hoursElapsed * 100.0 / totalHours, 97.0).toFixed(1)

  const [{ isDragging, isDraggingOnTeamTarget }, teamDrop] = useDrop({
    accept: 'expert',
    drop: () => ({ team: true }),
    collect: monitor => ({
      isDraggingOnTeamTarget: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  })

  const filterOptions = ['date', 'freelancer'];

  const availableFilterOptions = () => {
    return filterOptions.filter(filter => filter !== currentIdeaFilter);
  };

  const sortByDate = () => (
    [...filteredIdeas].sort((a,b) => b.updatedAtMoment() - a.updatedAtMoment())
  );

  const sortByFreelancer = () => (
    [...filteredIdeas].sort((a,b) => a.author.first_name.localeCompare(b.author.first_name))
  );


  const updateCurrentIdeaFilter = (event) => {
    const currentFilter = event.target.text;

    if (currentFilter === 'date') {
      setFilteredIdeas(sortByDate);
    } else if (currentFilter == 'freelancer') {
      setFilteredIdeas(sortByFreelancer);
    }

    setCurrentIdeaFilter(currentFilter);
  }

  const renderFilterDropdown = () => (
    <Dropdown>
      <Dropdown.Toggle className={styles.filterDropdownBtn}>
        { `Sort By: ${currentIdeaFilter}` }
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.filterDropdownMenu}>
        { availableFilterOptions().map((filter, k) => {
          return (
            <Dropdown.Item
              key={k}
              className={styles.filterDropdownItem}
              onClick={(e) => updateCurrentIdeaFilter(e)}
            >
              { filter }
            </Dropdown.Item>
          )}
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  const briefingComplete = job && job.briefing_document_url && job.briefing_video_url
  const briefingAllowed = (nonCustomerPerspective && jobStarted)

  const [vitalsCollapsedMobile, setVitalsCollapsedMobile] = useState(jobStarted)
  const [teamCollapsedMobile, setTeamCollapsedMobile] = useState(jobStarted)
  const [organizerCollapsedMobile, setOrganizerCollapsedMobile] = useState(jobStarted)
  const [briefCollapsedMobile, setBriefCollapsedMobile] = useState(jobStarted)

  const [currentIdeaFilter, setCurrentIdeaFilter] = useState(filterOptions[0])

  const hasFlaggedIdeas = job && !!job.ideas.find(i => i.flagged)

  return (
    <Container>
      <Row>
        <Col>
          { loading && !job && <Loading /> }
          { !loading && <Switch>
            { customerPerspective && <Route exact path={routes.END_JOB(':jobId')} render={(props) => <EndJob job={job} refreshJob={fetchJob} {...props} />} />}
            <Route exact path={routes.CREATE_IDEA(':jobId')} render={(props) => <CreateIdea job={job} refreshJob={fetchJob} {...props} />} />
            <Route exact path={routes.IDEA(':jobId', ':ideaId')} render={(props) => <ShowIdea job={job} refreshJob={fetchJob} {...props} />} />
            <Route exact path={routes.EDIT_IDEA(':jobId', ':ideaId')} render={(props) => <EditIdea job={job} refreshJob={fetchJob} {...props} />} />
            { customerPerspective && <Route exact path={routes.INVITE_MORE(':jobId')} render={(props) => <InviteMore job={job} refreshJob={fetchJob} {...props} />} />}
            <Route render={() => <>
              <ContentHeader noBorder={true} style={{marginBottom: 24, border: '1px solid black', padding: 12}}>
                <Row style={{paddingBottom: 25}}>
                  { customerPerspective ?
                  <Col md={6} lg={7} xl={8} style={{ textAlign: "left", display: 'flex', alignItems: "center" }}>
                    <span className="job-title">{job.title}</span>
                  </Col>
                  :
                  <Col md={12} lg={12} style={{ textAlign: "left", display: 'flex', alignItems: "center" }}>
                    <span className="job-title">{job.title}</span>
                  </Col>}
                  {customerPerspective && <>
                    <Col lg={5} md={6} xl={4} style={{margin: 'auto 0'}}>
                      { awaitingStart && <Button disabled={saving} onClick={startJob}>Start Job Now</Button> }
                      { !jobStarted && <div><Button onClick={handleShow}>Close Job</Button></div> }
                      { endSessionAllowed && <Button style={{width: 320}} type="primary" onClick={() => history.push(routes.END_JOB(job.slug)) }>
                        End Idea Session
                      </Button>}
                      { filteredIdeas && filteredIdeas.length > 0 && <>
                        <Button type="primary" onClick={() => downloadPdf(true)} disabled={downloading} style={{position: 'relative', width: 320}}>
                          { downloading ? <Loading style={{ position: 'absolute', top: 5}} className="spinner-sm" /> :
                                          <Icon path={mdiDownload} style={{height: 16, top: 3, position: 'relative'}} />}
                          {hasFlaggedIdeas ? 'Download Selected Ideas' : 'Download Idea Summary'}
                        </Button>
                      </>}
                    </Col>
                  </>}
                </Row>
              </ContentHeader>
              <Row>
                <Col xs={12}>
                  { awaitingLock && expertPerspective && <InstructionBox
                    show={true}
                    headline={'Waiting for Organizer to confirm'}>
                      Thanks! You've accepted this job. We're now waiting for the organizer, {job.creator.fullName}, to confirm your participation.
                  </InstructionBox>}
                  { awaitingStart && expertPerspective &&
                      <InstructionBox
                      show={true}
                      headline={'Waiting for job to begin'}>
                      Your participation is confirmed. As soon as the job begins, you'll be able & expected to add your ideas. We'll let you know when it does. Expected start date: on or about {moment(job.start_date).format("MM.DD.YY")}
                    </InstructionBox>
                  }
                </Col>

                <Col lg={{span: 7, order: 1}} xs={{span: 12, order: 1}}>
                  <div style={{ padding: 12, border: expertPerspective && !jobStarted ? null : "1px solid black", marginBottom: 12, paddingTop: awaitingLock || awaitingStart && customerPerspective ? 12 : 0 }}>
                    { awaitingLock && customerPerspective && <>
                      <div className={styles.customerInstructionsHeadline}>
                        While you're waiting for your freelancers to respond, upload your briefing materials
                      </div>
                      <div className={styles.customerInstructionsContent}>
                        The last step in prepping the job is to upload your creative brief and video. Both will show up at the top of the live job page after you have finalized the team. Below you can monitor the status of your invited freelancers and finalize your team of 4.
                      </div>
                    </>}
                    { awaitingStart && customerPerspective && <>
                      <div className={styles.customerInstructionsHeadline}>
                        Your job is ready!
                      </div>
                      <div className={styles.customerInstructionsContent}>
                        Your Freelancer team is locked in and eager to start! When you're ready to begin, click "Start Job Now". That'll open the flood gates for Freelancers to begin posting Ideas.
                      </div>
                    </>}
                    { jobStarted && <>
                      <div style={{marginTop: 20}}>
                        <span className={styles.subHeader}>
                          Idea Stream
                        </span>
                        {job.canParticipate && <button style={{float:'right'}} className="btn btn-primary btn-slim" type="button" onClick={navigateToCreateIdea} disabled={job.status === "closed" ? true : null}>
                          { job.status === "closed" ? "This job is Closed" : "Start New Idea"}
                        </button>}
                      </div>
                      <div style={{ marginTop: 12, marginBottom: 10 }}>
                        <Row>
                          <Col md={8} sm={12}>
                            <input
                              className={styles.searchBox}
                              placeholder="Search ideas"
                              onChange={handleSearchInputChange} />
                          </Col>
                          <Col md={4} sm={12}>
                            { renderFilterDropdown() }
                          </Col>
                        </Row>
                      </div>

                      { searching && <>
                        { searchLoading && !job && <Loading /> }
                        { !searchLoading && <div className="idea-stream"><IdeaList
                          job={job} setJob={setJob}  ideas={searchedIdeas} createIdea={navigateToCreateIdea} history={history} style={{marginBottom: 200}}
                          emptyContent="No ideas matching your search terms"
                        /></div>}
                      </>}
                      { !searching && <div className="idea-stream"><IdeaList job={job} setJob={setJob} ideas={filteredIdeas} createIdea={navigateToCreateIdea} history={history} style={{marginBottom: 200}} /></div>}
                    </>}
                  </div>
                </Col>

                {(customerPerspective || (nonCustomerPerspective && jobStarted)) && <Col lg={{span: 5, order: 2}} xs={{span: 12, order: 1}}>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <OverlayTrigger show={viewCallout && videoHelp} placement={popoverPlacement} overlay={
                        <Popover className={`${styles.popover} red`} >
                          <Popover.Content>
                            <div style={{display: 'flex'}}>
                              <span>Upload a single page creative brief, a short video (under 10 minutes, nothing fancy) explaining the brief to the team, and any other supporting docs.</span>
                              <CloseButton onClick={() => setVideoHelp(false)} className="popover-close" />
                            </div>
                          </Popover.Content>
                        </Popover>
                      }>
                        <div className={styles.rightColBox}>
                          <MiniHeader text="Brief" style={{marginBottom: 17 }}>
                            { viewCallout && <SvgInfoIcon onClick={() => setVideoHelp(!videoHelp)} style={{ display: "inline-block", cursor: 'pointer', paddingLeft: 6}}/>}

                            <ExpandCollapseSectionButton isExpanded={briefCollapsedMobile} setExpanded={setBriefCollapsedMobile} />
                          </MiniHeader>
                          <Collapse in={briefCollapsedMobile} className="d-lg-block">
                            <div>
                              {customerPerspective && <>
                              <Col sm={12}>
                                { briefingComplete && renderVideo(job) }
                              </Col>
                              { renderFileList(job, setJob, customerPerspective) }
                              </>}
                              {briefingAllowed && briefingComplete && (
                                <>
                                  <Col sm={12}>
                                    { renderVideo(job) }
                                  </Col>
                                  { renderFileList(job, setJob, customerPerspective) }
                                </>
                            )}
                            </div>
                          </Collapse>
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                      <div className={styles.rightColBox}>
                        <MiniHeader text="Vitals">
                          <ExpandCollapseSectionButton isExpanded={vitalsCollapsedMobile} setExpanded={setVitalsCollapsedMobile} />
                        </MiniHeader>
                        <Collapse in={vitalsCollapsedMobile} className="d-lg-block">
                          <div className={styles.boxInnerPadding}>
                            <div style={{fontWeight: 'bold', fontSize: 13, float: 'right'}}>Finish</div>
                            <div style={{fontWeight: 'bold', fontSize: 13}}>Start</div>
                            <div className={styles.gradientCell} style={{position: 'relative'}}>
                              {!timeOutOfRange && <SvgTimeBarIndicator style={{position: 'absolute', top: -6, left: `${percentageElapsed}%`}} />}
                              <div className={styles.timebarLabel} style={{left: 12}}>{moment(job.start_date).format("MM.DD.YY")}</div>
                              <div className={styles.timebarLabel} style={{right: 12}}>{moment(job.end_date).format("MM.DD.YY")}</div>
                              <div  className={styles.gradient} />
                            </div>
                            { job.payment_type !== 'none' && <>
                              <div style={{fontWeight: 'bold', fontSize: 13, float: 'left', marginTop: 33}}>Spoils</div>
                              <div style={{fontSize: 10, float: 'right', paddingLeft: 5, marginTop: 24}}>Per<br />Freelancer</div>

                              <div style={{fontWeight: 'bold', fontSize: 28, marginTop: 19, textAlign: 'right'}}>
                                { job.payment_type === 'equal' && job.per_expert_budget }
                                { job.payment_type === 'custom' && job.customized_budget }
                              </div>
                            </>}
                          </div>
                        </Collapse>
                      </div>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                      <div className={styles.rightColBox}>
                        <MiniHeader text="Idea Team" style={{marginBottom: 17 }}>
                          { Config.manageTeamEnabled && customerPerspective && !awaitingLock && job.status !== 'closed' && <div style={{ display: !teamCollapsedMobile && isMobile ? 'none' : 'initial'}} className={styles.manage_team} onClick={()=>setEdittingTeam(!edittingTeam)}><span className={ edittingTeam ? styles.toggleBtnArrowUp : styles.toggleBtnArrowDown}>&#9662;</span>Manage Team</div>}
                          <ExpandCollapseSectionButton isExpanded={teamCollapsedMobile} setExpanded={setTeamCollapsedMobile} />
                        </MiniHeader>
                        <Collapse in={teamCollapsedMobile} className="d-lg-block">
                          <div>
                            { state.currentUser.id === job.creator.id && !jobStarted && !awaitingStart ? selectedExpert ?
                            <ExpertShortProfile expert={selectedExpert} >
                              {
                                !selectedExpert || teamIncludes(selectedExpert)
                                  ?
                                null
                                  :
                                  selectedExpert.expert_accepted &&
                                  <button type="button" className="btn btn-primary btn-mini" onClick={() => addToTeam(selectedExpert)}>
                                  Add
                                </button>
                              }
                              </ExpertShortProfile>
                              :
                              null
                              :
                              null
                            }

                          { awaitingLock && <>
                            <div style={{fontSize: 13, marginBottom: 11}}>
                              Status of Invited Freelancers
                              { viewCallout && <SvgInfoIcon onClick={() => setExpertHelp(!expertHelp)} style={{ display: "inline-block", cursor: 'pointer', paddingLeft: 6, position: 'relative', top: 3}}/>}
                            </div>
                              <OverlayTrigger show={viewCallout && expertHelp} placement={popoverPlacement} overlay={
                                <Popover className={`${styles.popover} orange`} >
                                  <Popover.Content>
                                    <div style={{display: 'flex'}}>
                                      <span>Here you can monitor the acceptance status of the freelancers you've invited. You may need to refresh the page.</span>
                                      <CloseButton onClick={() => setExpertHelp(false)} className="popover-close" />
                                    </div>
                                  </Popover.Content>
                                </Popover>
                              }>
                                <ExpertGrid
                                  addToTeam={addToTeam}
                                  experts={job.experts}
                                  dragType="expert"
                                  borderFn={expertBorder}
                                  allowDrag={(expert) => expert.expert_accepted}
                                  setSelectedExpert={setSelectedExpert}
                                  selectedExpert={selectedExpert}
                                >
                                  {(expert) => (
                                    teamIncludes(expert)
                                      ?
                                    null
                                      :
                                      <div style={{position: 'absolute', bottom: 1, right: 5, cursor: 'pointer'}} onClick={() => addToTeam(expert)} >
                                        { expert.expert_accepted && <SvgPlusIcon style={{height: 14, width: 14}} />}
                                      </div>
                                  )}
                                </ExpertGrid>
                              </OverlayTrigger>

                              <div style={{textAlign: 'center', marginTop: 17}}>
                                <Button type="primary" onClick={() => history.push(routes.INVITE_MORE(job.id))} disabled={saving}>
                                  Invite More
                                </Button>
                              </div>
                              <MiniHeader text="" style={{marginTop: 17, marginBottom: 17 }}/>
                              <div style={{fontSize: 13, marginBottom: 11}}>
                                Final Team
                                { viewCallout && <SvgInfoIcon onClick={() => setFinalizeHelp(!finalizeHelp)} style={{ display: "inline-block", cursor: 'pointer', paddingLeft: 6, position: 'relative', top: 3}}/>}
                              </div>
                              <OverlayTrigger show={viewCallout && finalizeHelp} placement={popoverPlacement} overlay={
                                <Popover className={`${styles.popover} yellow`} >
                                  <Popover.Content>
                                    <div style={{display: 'flex'}}>
                                      <span>Once at least 4 have accepted, drag your final four freelancers to the Final Team box. Then click "Finalize Team" button to automatically notify your freelancers via email with a link to the live job.</span>
                                      <CloseButton onClick={() => setFinalizeHelp(false)} className="popover-close" />
                                    </div>
                                  </Popover.Content>
                                </Popover>
                              }>
                                <ExpertDropTarget
                                  drop={teamDrop}
                                  team={team}
                                  isDragging={isDragging} isDraggingOnTeamTarget={isDraggingOnTeamTarget}
                                  hint={isBrowser ? <>Drag and drop from the invited list<br />to finalize your team!</> : <>Add Freelancers from the invited list<br />to finalize your team!</>}
                                  expertGridProps={{dragType: "team", selectedExpert: selectedExpert, setSelectedExpert: setSelectedExpert, removeFromTeam: removeFromTeam, borderFn: expertBorder}}
                                  expertGridChildRender={(expert) => (
                                    <div style={{position: 'absolute', bottom: 2, right: 6, cursor: 'pointer'}} onClick={() => removeFromTeam(expert)} >
                                      { <SvgRemoveIcon style={{height: 12, width: 12}} />}
                                    </div>
                                  )}
                                />
                              </OverlayTrigger>

                              <div style={{textAlign: 'center', marginTop: 17}}>
                                <Button type="primary" onClick={()=>setStartJobModal(true)} disabled={team.length === 0 || saving}>
                                  Finalize Team
                                </Button>
                              </div>
                          </>}

                          { !awaitingLock && edittingTeam && <>
                              <div style={{fontSize: 13, marginBottom: 11}}>
                                Final Team
                                { viewCallout && <SvgInfoIcon onClick={() => setFinalizeHelp(!finalizeHelp)} style={{ display: "inline-block", cursor: 'pointer', paddingLeft: 6, position: 'relative', top: 3}}/>}
                              </div>
                              <OverlayTrigger show={viewCallout && finalizeHelp} placement={popoverPlacement} overlay={
                                <Popover className={`${styles.popover} yellow`} >
                                  <Popover.Content>
                                    <div style={{display: 'flex'}}>
                                      <span>Once at least 4 have accepted, drag your final four freelancers to the Final Team box. Then click "Finalize Team" button to automatically notify your freelancers via email with a link to the live job.</span>
                                      <CloseButton onClick={() => setFinalizeHelp(false)} className="popover-close" />
                                    </div>
                                  </Popover.Content>
                                </Popover>
                              }>
                                <ExpertDropTarget
                                  drop={teamDrop}
                                  team={team}
                                  isDragging={isDragging} isDraggingOnTeamTarget={isDraggingOnTeamTarget}
                                  hint={isBrowser ? <>Drag and drop from the invited list<br />to finalize your team!</> : <>Add Freelancers from the invited list<br />to finalize your team!</>}
                                  expertGridProps={{dragType: "team", selectedExpert: selectedExpert, setSelectedExpert: setSelectedExpert, removeFromTeam: removeFromTeam, borderFn: expertBorder}}
                                  expertGridChildRender={(expert) => (
                                    <div style={{position: 'absolute', bottom: 2, right: 6, cursor: 'pointer'}} onClick={() => removeFromTeam(expert)} >
                                      { <SvgRemoveIcon style={{height: 12, width: 12}} />}
                                    </div>
                                  )}
                                />
                              </OverlayTrigger>

                              <div style={{textAlign: 'center', marginTop: 17}}>
                                <Button type="primary" onClick={()=>setStartJobModal(true)} disabled={team.length === 0 || saving}>
                                  Finalize Team
                                </Button>
                                <MiniHeader text="" style={{marginTop: 17, marginBottom: 17 }}/>
                              </div>
                              <div style={{fontSize: 13, marginBottom: 11}}>
                              Make changes to your team mid-job by deleting a freelancer's card (click x in bottom right) to remove them or by adding a new team member by clicking "Invite More".
                              { viewCallout && <SvgInfoIcon onClick={() => setExpertHelp(!expertHelp)} style={{ display: "inline-block", cursor: 'pointer', paddingLeft: 6, position: 'relative', top: 3}}/>}
                            </div>
                              <OverlayTrigger show={viewCallout && expertHelp} placement={popoverPlacement} overlay={
                                <Popover className={`${styles.popover} orange`} >
                                  <Popover.Content>
                                    <div style={{display: 'flex'}}>
                                      <span>Here you can monitor the acceptance status of the freelancers you've invited. You may need to refresh the page.</span>
                                      <CloseButton onClick={() => setExpertHelp(false)} className="popover-close" />
                                    </div>
                                  </Popover.Content>
                                </Popover>
                              }>
                                <ExpertGrid
                                  addToTeam={addToTeam}
                                  experts={job.experts}
                                  dragType="expert"
                                  borderFn={expertBorder}
                                  allowDrag={(expert) => expert.expert_accepted}
                                  setSelectedExpert={setSelectedExpert}
                                  selectedExpert={selectedExpert}
                                >
                                  {(expert) => (
                                    teamIncludes(expert)
                                      ?
                                    null
                                      :
                                      <div style={{position: 'absolute', bottom: 1, right: 5, cursor: 'pointer'}} onClick={() => addToTeam(expert)} >
                                        { expert.expert_accepted && <SvgPlusIcon style={{height: 14, width: 14}} />}
                                      </div>
                                  )}
                                </ExpertGrid>
                              </OverlayTrigger>

                              <div style={{textAlign: 'center', marginTop: 17}}>
                                <Button type="primary" onClick={() => history.push(routes.INVITE_MORE(job.id))} disabled={saving}>
                                  Invite More
                                </Button>
                              </div>

                          </>}


                          { !awaitingLock && !edittingTeam && <ExpertGrid experts={experts} dragType="none" borderFn={() => '1px solid black'} allowDrag={() => false}>
                            {(expert) => <ExpertMessageButton expert={expert} />}
                          </ExpertGrid>
                          }
                          </div>
                        </Collapse>
                      </div>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                      <div className={styles.rightColBox}>
                        <ClientTeamSection job={job} setJob={setJob}
                          organizerCollapsedMobile={organizerCollapsedMobile}
                          setOrganizerCollapsedMobile={setOrganizerCollapsedMobile}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>}
              </Row>
              {deleteModal &&
              <>
                <Modal
                  show={deleteModal}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                  <Modal.Title>Delete Job</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <h2>{`Are you sure you'd like to delete "${job.title}"?`}</h2>
                      <h3>This action can't be undone and will be permanently removed.</h3>
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="success" onClick={()=>deleteJob(job.id)}>
                      Delete Job
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                      Close
                  </Button>
                  </Modal.Footer>
                </Modal>
              </>
              }
              { startJobModal &&
              <>
                <Modal
                  show={startJobModal}
                  onHide={()=>setStartJobModal(false)}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton style={{textAlign: "center"}}>
                  <Modal.Title>Finalize Team</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h2>Finalizing locks in your team. We'll let the freelancers know they've been chosen to work on the project.</h2>
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="success" onClick={()=>{finalizeTeam(); setStartJobModal(false) }}>
                      Finalize Team
                  </Button>
                  <Button variant="secondary" onClick={()=>setStartJobModal(false)}>
                      Close
                  </Button>
                  </Modal.Footer>
                </Modal>
              </>
              }
            </>} />
          </Switch>}
        </Col>
      </Row>
    </Container>
  )
}
