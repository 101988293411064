import React, { useState, useEffect } from 'react'
import tileStyles from '../../dashboard/tiles.module.css'
import styles from './styles.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import MiniHeader from 'components/general/mini_header'
import RichContent from '../idea/rich_content.jsx'
import { SvgCommentIcon, SvgFlagIcon, SvgFlagActiveIcon } from 'components/general/icons'
import Flag from '../idea/flag'
import Avatar from 'components/general/avatar'
import { OverflowDetector } from 'react-overflow';
import Button from 'components/general/button'
import { Row, Col } from 'react-bootstrap';
import autoTypeCast from 'auto-type-cast';

export default function IdeaTile ({job, setJob, history, ...props}) {
  const [idea, setIdea] = useState(props.idea)
  const [more, setMore] = useState(false)
  const [hasOverflow, setHasOverflow] = useState(false)
  const [ hasYoutubeVideo, setHasYoutubeVideo] = useState(false)

  const navigateToIdea = () => history.push({ pathname: routes.IDEA(job.slug, idea.slug), state: { idea: idea }})

  const handleOverflow = (hasOveflow) => {
    if(hasOveflow) {
      // when we have overflow, we will hide the overflow, so ignore when this function
      // is later called again with a false param
      setHasOverflow(true)
    }
  }

  useEffect(()=>{
    if(hasYoutubeVideo) return

    try {
      if (idea.content.includes('youtube.com')){
        let youtube = idea.content.split(" ").find(str =>
          str.includes("src") && str.includes("https://www.youtube.com")
        )
        if(!youtube) return

        let srcWithQuotes = youtube.match(/src\=([^\s]*)\s/)[1]
        let src = srcWithQuotes.substring( 1,srcWithQuotes.length - 47 )
        getThumb(src)
      }
    } catch(e) {
      console.error("Unable to determine youtube thumbnail", e)
    }
  }, [idea, hasYoutubeVideo])

  const getThumb = (url, size) => {
    let results = url.match('[\\?&]v=([^&#]*)');
    let video = (results === null) ? url : results[1];
    setHasYoutubeVideo('http://img.youtube.com/vi/' + video + '/2.jpg')
  };


  return (
    <div className={styles.ideaTile} {...props}>
      <MiniHeader style={{cursor: 'pointer'}}
        text={idea.title.toUpperCase()}
        subtext={idea.updatedAtFormatted}
        onClick={navigateToIdea} />

      { idea.image_src && (
        <Row>
          <Col md={{span: 4, offset: 8}} xs={0}>
            <img src={idea.image_src} alt={idea.title} className={styles.firstIdeaImg} />
          </Col>
        </Row>
      )}
      { !idea.image_src && hasYoutubeVideo && (
        <Row>
          <Col md={{span: 4, offset: 8}} xs={0}>
            <img src={hasYoutubeVideo} alt={idea.title} className={styles.firstIdeaImg} style={{ zIndex: 99, width: '100%' }} />
          </Col>
        </Row>
      )}
      <div onClick={navigateToIdea} className={idea.image_src || hasYoutubeVideo  ? 'col-md-8' : 'col-lg-12'}>
        <OverflowDetector className={styles.ideaContent} style={hasOverflow ? { overflowY: 'hidden' } : {}} onOverflowChange={(value) => setHasOverflow(value)}>
          { hasOverflow && <div style={{
            opacity: 0.65,
            position: 'absolute',
            top: 40,
            height: 70,
            pointerEvents: 'none',
            background: 'linear-gradient(rgba(242, 244, 244, 0), rgba(242, 244, 244, 1))',
            zIndex: 1,
            width: '100%' }} />}
          <RichContent content={idea.content} />
        </OverflowDetector>
        { false && hasOverflow && <div style={{textAlign: 'center'}}>
          <button className="btn btn-slim" style={{position: 'relative', top: -15, zIndex: 2, marginLeft: 'auto', marginRight: 'auto'}} onClick={navigateToIdea}>See More</button>
        </div>}
      </div>
      <div className={styles.ideaFooter}>
        <div className={styles.ideaCreatedAt}>{idea.createdAtFormatted}</div>
        <Avatar className={styles.ideaAuthor} record={idea.author} />
        <div className={styles.riffCount} onClick={navigateToIdea}>
          { idea.comments_count > 0 && <>
            <SvgCommentIcon /><span className={styles.label}>{ idea.comments_count } Build{idea.comments_count != 1 && 's'}</span>
          </>}
        </div>
        <Flag idea={idea} job={job} setIdea={(idea) => {
          setIdea(idea);
          const newJob = Object.assign({}, job)
          autoTypeCast(newJob)
          setJob(newJob)
        }} style={{float: 'right'}}/>
      </div>
    </div>
  )
}
