import React, { Component, Fragment, useState } from 'react'
import styles from './styles.module.css'

class EditableDateInput extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  focus() {
    this.ref.current.focus()
  }


  render() {

      console.log(this.props)
    return (
      <input ref={this.ref} type="date" min={this.props.min} onChange={this.props.setter} />
    )
  }
}

const Date = ({ value }) => (
  <span className={styles.value}>
    {value.getMonth() + 1}.{value.getDate()}.{value.getFullYear()}
  </span>
)

const ref = React.createRef()

export default function EditableDate ({ value, setter, ...props }) {
  const [ isEditing, setIsEditing ] = useState(false)

  const handleClick = () => {
    setIsEditing(true)
    setTimeout(() => ref.current.focus(), 0)
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  return (
    <span onClick={handleClick} onBlur={handleBlur}>
      {isEditing ? <EditableDateInput ref={ref} setter={setter} {...props} /> : <Date value={value} />}
    </span>
  )
}
