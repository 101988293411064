import resourceBuilder from './resource_builder'
import defaultResourceSchema from './default_resource_schema'

const { index, create } = defaultResourceSchema

const adminExpertsResource = resourceBuilder.build('/admin/experts', {
  index,
  create
})

export default adminExpertsResource
