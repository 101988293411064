import React, { Fragment, memo, useContext, useEffect } from 'react'
import styles from './styles.module.css'
import Store from 'store'
import {
  SIGN_IN as SIGN_IN_PATH,
  NOTIFICAITON_SETTINGS as NOTIFICAITON_SETTINGS_PATH,
  EDIT_PROFILE as EDIT_PROFILE_PATH,
  MANAGE_ORGANIZATION as MANAGE_ORGANIZATION_PATH,
  MANAGE_BILLING as MANAGE_BILLING_PATH,
  JOB_PRINT as JOB_PRINT_PATH,
  ADMIN_MANAGE_IDEASICLE_EXPERTS
} from 'constants/routes'
import { HistoryProvider } from 'components/app/history'
import logoHeaderSrc from './logo-header.svg'
import logoHeaderSrcLightMode from './logo-header-light-mode.svg'
import { SvgMenuIcon } from 'components/general/icons'
import { withRouter } from 'react-router-dom'
import Avatar from '../avatar.jsx'
import classnames from 'classnames'
import { NavDropdown, Nav, Dropdown, Navbar } from 'react-bootstrap'
import sessionsResource from '../../../resources/sessions'
import statsResource from '../../../resources/stats'
import './dropdowns.scss';
import { LinkContainer } from 'react-router-bootstrap'
import download from 'downloadjs'
import { Route } from 'react-router-dom'
import w9sResource from 'resources/w9s'
import useMasterCommandCenterLink from 'utils/useMasterCommandCenterLink'
import * as actions from 'constants/actions'

function SignedInBar ({ unauthenticatedMode, children, history, ...props }) {
  const { dispatch, state } = useContext(Store)

  useEffect(() => {
    if (!state.currentUser && !unauthenticatedMode) {
      HistoryProvider.history.push(SIGN_IN_PATH)
    }
  }, [state.currentUser, unauthenticatedMode])

  const mode = (unauthenticatedMode || state.currentUser?.role === 'expert') ? 'light' : 'dark'

  const MenuToggle = React.forwardRef(({ children, onClick, style, ...props }, ref) => (
    <a
      href="#"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      <div style={style} ref={ref}>
        <SvgMenuIcon mode={mode} />
      </div>
    </a>
  ));

  const {
    reactRouterLinkProps: masterCommandCenterLinkProps,
    anchorTagProps: masterCommandCenterAnchorProps,
  } = useMasterCommandCenterLink()

  const MasterCommandCenterLinkContainer = masterCommandCenterLinkProps ? LinkContainer : React.Fragment

  const downloadStats = async (endpointName) => {
    const response = await statsResource[endpointName]() // e.g. statsResource.platform()
    download(
      response.data,
      `${endpointName}.csv`,
      response.headers['content-type']
    )
  }

  const downloadW9s = async () => {
    const response = await w9sResource.index()

    download(
      response.data.zip_url,
    )
  }

  const editProfile = state.currentUser && state.currentUser.role === 'expert'
  const hasNotificationSettings = state.currentUser && (state.currentUser.role === 'expert' || state.currentUser.role === 'customer')

  const mbClass = props.mbClass || 'mb-5'


  if (!unauthenticatedMode && !state.currentUser) return null
  
  unauthenticatedMode = unauthenticatedMode || !state.currentUser?.role



  const signOut = async () => {
    const result = await sessionsResource.signOut()
    dispatch({type: actions.CURRENT_USER_FETCHED, payload: null})
    history.push(SIGN_IN_PATH)
  }


  return (
    <Fragment>
      <Navbar collapseOnSelect expand="md" className={classnames(styles.bar, styles[mode], mbClass, 'py-0', 'justify-content-between')}>
        <div style={unauthenticatedMode ? {} : { cursor: 'pointer'}} onClick={() => { if(!unauthenticatedMode) { history.push('/') }}}>
          <img src={mode === 'light' ? logoHeaderSrcLightMode : logoHeaderSrc} alt="Ideasicle X" />
          {state.currentUser && state.currentUser.organization &&
            <Avatar record={state.currentUser.organization} hideIfNoAvatar={true} className={styles.companyAvatar} />
          }
        </div>
        {state.currentUser.role && <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <MenuToggle style={{paddingTop: 5, paddingBottom: 2}} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" style={{zIndex: 1, backgroundColor: 'white'}}>
            <Nav className="mr-auto d-md-none">
              <Dropdown.Header>
                <Avatar record={state.currentUser} className={styles.userAvatar} />
                <span className={styles.userName}>{state.currentUser.fullName}</span>
              </Dropdown.Header>
              {editProfile && <LinkContainer to={EDIT_PROFILE_PATH}>
                <Nav.Link className={styles.dropdownItem}>
                  Edit Profile
                </Nav.Link>
              </LinkContainer>}
              {state.currentUser.role === 'expert' && <Nav.Link className={styles.dropdownItem} href="https://www.ideasiclex.com/freelancer-guide" target="_blank">
                  Freelancer Guide
                </Nav.Link>}
              {state.currentUser.role === 'customer' && <Nav.Link className={styles.dropdownItem} href="https://www.ideasiclex.com/customer-guide" target="_blank">
                  Customer Guide
                </Nav.Link>}
              {state.currentUser.role === 'customer' && state.currentUser['is_organization_owner?'] && <LinkContainer to={MANAGE_ORGANIZATION_PATH}>
                <Nav.Link className={styles.dropdownItem}>
                  Account Status
                </Nav.Link>
              </LinkContainer>}
              {state.currentUser.billingFeaturesAllowed() && <MasterCommandCenterLinkContainer  {...masterCommandCenterLinkProps}>
                <Nav.Link className={styles.dropdownItem} {...masterCommandCenterAnchorProps}>
                  Master Command Center
                </Nav.Link>
              </MasterCommandCenterLinkContainer>}
              {hasNotificationSettings && <>
                <LinkContainer to={NOTIFICAITON_SETTINGS_PATH}>
                  <Nav.Link className={styles.dropdownItem}>
                    Notification Settings
                  </Nav.Link>
                </LinkContainer>
                <Dropdown.Divider />
              </>}
              {state.currentUser.admin && <>
                <Nav.Link className={styles.dropdownItem} onClick={() => downloadStats('platform')}>
                  Download Platform Stats (CSV)
                </Nav.Link>
                <Nav.Link className={styles.dropdownItem} onClick={() => downloadStats('customers')}>
                  Download Customer Stats (CSV)
                </Nav.Link>
                <Nav.Link className={styles.dropdownItem} onClick={() => downloadStats('users')}>
                  Download Users Report (CSV)
                </Nav.Link>
                <Nav.Link className={styles.dropdownItem} onClick={downloadW9s}>
                  Download W-9 Forms (ZIP)
                </Nav.Link>
                <Nav.Link className={styles.dropdownItem} onClick={()=>history.push(ADMIN_MANAGE_IDEASICLE_EXPERTS)}>
                  Manage Ideasicle Experts
                </Nav.Link>
                <Dropdown.Divider />
              </>}
              <Nav.Link className={styles.dropdownItem} onClick={signOut}>
                Sign Out
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Dropdown alignRight className="print-hide d-none d-md-block">
            <Dropdown.Toggle as={MenuToggle} style={{paddingTop: 10, paddingBottom: 7, paddingRight: 13}} />
            <Dropdown.Menu>
              <Dropdown.Header>
                <Avatar record={state.currentUser} className={styles.userAvatar} />
                <span className={styles.userName}>{state.currentUser.fullName}</span>
              </Dropdown.Header>
              {editProfile && <LinkContainer to={EDIT_PROFILE_PATH}>
                <Dropdown.Item className={styles.dropdownItem}>
                  Edit Profile
                </Dropdown.Item>
              </LinkContainer>}
              {state.currentUser.role === 'expert' && <Dropdown.Item className={styles.dropdownItem} href="https://www.ideasiclex.com/freelancer-guide" target="_blank">
                  Freelancer Guide
                </Dropdown.Item>}
              {state.currentUser.role === 'customer' && <Dropdown.Item className={styles.dropdownItem} href="https://www.ideasiclex.com/customer-guide" target="_blank">
                  Customer Guide
                </Dropdown.Item>}
              {state.currentUser.role === 'customer' && state.currentUser['is_organization_owner?'] && <LinkContainer to={MANAGE_ORGANIZATION_PATH}>
                <Dropdown.Item className={styles.dropdownItem}>
                  Account Status
                </Dropdown.Item>
              </LinkContainer>}
              {state.currentUser.billingFeaturesAllowed() && <MasterCommandCenterLinkContainer {...masterCommandCenterLinkProps}>
                <Dropdown.Item className={styles.dropdownItem} {...masterCommandCenterAnchorProps}>
                  Master Command Center
                </Dropdown.Item>
              </MasterCommandCenterLinkContainer>}

              {hasNotificationSettings && <>
                <LinkContainer to={NOTIFICAITON_SETTINGS_PATH}>
                  <Dropdown.Item className={styles.dropdownItem}>
                    Notification Settings
                  </Dropdown.Item>
                </LinkContainer>
                <Dropdown.Divider />
              </>}
              {state.currentUser.admin && <>
                <Dropdown.Item className={styles.dropdownItem} onClick={() => downloadStats('platform')}>
                  Download Platform Stats (CSV)
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropdownItem} onClick={() => downloadStats('customers')}>
                  Download Customer Stats (CSV)
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropdownItem} onClick={() => downloadStats('users')}>
                  Download Users Report (CSV)
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropdownItem} onClick={downloadW9s}>
                  Download W-9 Forms (ZIP)
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropdownItem} onClick={()=>history.push(ADMIN_MANAGE_IDEASICLE_EXPERTS)}>
                  Manage Ideasicle Experts
                </Dropdown.Item>
                <Dropdown.Divider />
              </>}
              <Dropdown.Item className={styles.dropdownItem} onClick={signOut}>
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>}
      </Navbar>

      {children}
    </Fragment>
  )
}

export default withRouter(SignedInBar)
