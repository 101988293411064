import React, { useContext, useMemo, useEffect } from 'react'
import styles from './styles.module.css'
import CurrencyInput from 'react-currency-input';
import { moneyConverter } from 'components/jobs/create'
import * as ExpertInviteStep from './expert_invite_step'
import  { ExpertCard } from './expert_invite_step.jsx'
import { Row, Col, Modal } from 'react-bootstrap'
import classNames from '../../../utils/classNames'
import { formatCurrency } from '../../../utils/formatters'
import Discount from 'components/general/discount'
import Store from 'store'

const renderPct = (amount) => Math.floor(amount * 100) + '%'

const BudgetModal = ({ show, onClose, attributes, invitedTeam, expertBudgetsById, expertBudgetsByEmail, expertBudget, onSubmitBudget, onSubmitIndividual, submitting }) => {
  const teamHasBudgetedFreelancers = invitedTeam?.length && invitedTeam.filter( (e) => e.expert_type != 'employee').length
  const newFreelancerValidEmails = ExpertInviteStep.getValidEmails(attributes.myOwnFreelancerEmails)
  const showBudgetOption = attributes.perExpertBudget && (!!teamHasBudgetedFreelancers || !!newFreelancerValidEmails.length)
  const { dispatch, state } = useContext(Store)
  const additionalFeePct = useMemo(() => state.currentUser?.additionalFeePct(), [state.currentUser])
  const additionalFee = showBudgetOption && attributes.numberOfExperts.value * attributes.perExpertBudget.value * additionalFeePct.discountedAmount

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      style={{border: '2px solid black'}}
    >
      <Modal.Header closeButton>
      <Modal.Title>How much do you propose paying the freelancers for this job?</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0}}>
        {showBudgetOption && (
          <div className={styles.fixedBudget}>
            <h3 className={styles.setBudgetHeader}>Option #1: By Budget</h3>
            <span className={styles.setBudgetText}>{`Input total amount you wish to pay your team of 4 freelancers and each freelancer will be proposed the same amount:`}</span>
            <Row>
              <Col>
                <table className={classNames([styles.budget_table, styles.budget_table_v3])}>
                <tbody>
                  <tr>
                    <td className={styles.setBudgetText} style={{paddingRight: 15, whiteSpace: 'nowrap'}}>Freelancer Budget:</td>
                    <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v3])} style={{paddingLeft: 0}}>
                    <CurrencyInput
                      value={expertBudget}
                      className={classNames([styles.budget_input_v3, styles.budgetValueInputV3])}
                      onChangeEvent={(e) => {
                        const newEvent = { persist: () => {}, target: { value: (moneyConverter(e.target.value) / attributes.numberOfExperts.value).toString() }}
                        attributes.perExpertBudget.eventHandlers.onChange(newEvent)
                      }}
                      precision="0"
                      prefix="$"/>
                    </td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 16, paddingRight: 15, whiteSpace: 'nowrap'}}>Payment per Freelancer</td>
                    <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v3])}>{formatCurrency(attributes.perExpertBudget.value)}</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 16, paddingRight: 15, whiteSpace: 'nowrap'}}>Handling Fees (<Discount formatter={renderPct} discountColor={'white'} {...additionalFeePct} />)</td>
                    <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v3])}>{formatCurrency(additionalFee)}</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 16, paddingRight: 15, whiteSpace: 'nowrap'}} className={classNames([styles.budget_table_total_label])}>
                      Total Budget
                    </td>
                    <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v3])}>
                      {formatCurrency(expertBudget + additionalFee)}
                    </td>
                  </tr>
                </tbody>
                </table>
              </Col>
            </Row>
            <div style={{marginTop: 10, textAlign: 'right'}}>
              <button disabled={submitting} className="btn btn-primary btn-slim" style={{width: 200}} type="button" onClick={onSubmitBudget}>
                Invite Freelancers
              </button>
            </div>
          </div>
        )}
        <div style={{padding: 20}}>
          {showBudgetOption && (<h3 className={styles.setBudgetHeader}>Option #2: By Individual Freelancer</h3>)}
          <span className={styles.setBudgetText}>
            You can {showBudgetOption && 'also'} customize proposed payments by freelancer. Note: a <Discount formatter={renderPct} {...additionalFeePct} /> handling fee will be added to your final combination.
          </span>
          <table className={classNames([styles.budget_table, styles.budget_table_v3])} style={{ marginTop: 12 }}>
            <thead>
              <tr>
                <th className={styles.budgetTh}>Freelancer: </th>
                <th className={styles.budgetTh} style={{ textAlign: 'right' }}>Proposed Payment: </th>
              </tr>
            </thead>
            <tbody>
              {invitedTeam.length ? invitedTeam.map((expert)=>{
                if (expert.expert_type != 'employee'){
                  return(
                    <tr key={expert.id}>
                      <td>
                        <div style={{ marginLeft: -10, height: 75 }}>
                          <ExpertCard transparentBg={true} key={expert.id} expert={expert} dragType="none" allowDrag={false}/>
                        </div>
                      </td>
                      <td style={{ textAlign: 'right', marginBottom: 10  }}>
                        <CurrencyInput
                          className={classNames([styles.budget_input_v3, styles.budgetValueInputV3])}
                          precision="0"
                          prefix="$"
                          value={expertBudgetsById[expert.id]}
                          onChange={(e) => { expertBudgetsById[expert.id] = e}}
                        />
                      </td>
                    </tr>
                  )
                }
              }) : null}
              { newFreelancerValidEmails.map((email) => {
                return(
                  <tr key={email}>
                    <td>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>{email}</span>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <CurrencyInput
                        className={classNames([styles.budget_input_v3, styles.budgetValueInputV3])}
                        precision="0"
                        prefix="$"
                        value={expertBudgetsByEmail[email]}
                        onChange={(e) => { expertBudgetsByEmail[email] = e}}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div style={{marginTop: 16, textAlign: 'right'}}>
            <button disabled={submitting} className="btn btn-primary btn-slim" style={{width: 200}} type="button" onClick={onSubmitIndividual}>
              Invite Freelancers
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BudgetModal
