import React, { useState, useMemo, useContext, useEffect, useLayoutEffect } from 'react'
import {Helmet} from "react-helmet";
import Store from 'store'
import { Container, Row, Col, Accordion, useAccordionToggle, Popover, OverlayTrigger } from 'react-bootstrap'
import ContentHeader from 'components/general/content_header'
import jobsResource from 'resources/jobs'
import expertsResource from 'resources/experts'
import Loading from 'components/general/loading'
import JobTiles from './job_tiles'
import * as routes from 'constants/routes'
import { HistoryProvider } from 'components/app/history'
import expertStyles from './expert.module.css'
import customerStyles from './customer.module.css'
import Avatar from 'components/general/avatar.jsx'
import moment from 'moment'
import notificationsResource from 'resources/notifications'
import { toast } from 'react-toastify';
import NotificationsTile from './notifications'
import  { ExistingExpertShortProfile, ExpertCard } from '../jobs/create/expert_invite_step.jsx'
import { pathAfterSignIn } from 'utils/local_storage'
import { formatCurrency } from 'utils/formatters'
import Button from '../general/button'


const fetchNotifications = async (setNotifications, setLoading) => {
  try {
    setLoading(true)
    const response = await notificationsResource.index()
    setNotifications(response.data)
  } catch (e) {
    toast.warn("Uh oh! We weren't able to fetch your notifications. Please try again soon.")
  } finally {
    setLoading(false)
  }
}

function CustomerDashboard({history}) {
  const { dispatch, state } = useContext(Store)
  const [jobs, setJobs] = useState(null)
  const [favorites, setFavorites] = useState(null)
  const [loadingJobs, setLoadingJobs] = useState(true)
  const [loadingFavorites, setLoadingFavorites] = useState(true)
  const [visibleJobCount, setVisibleJobCount] = useState(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const newJobPrivilege = useMemo(() =>
    state.currentUser && jobs && state.currentUser.newJobPrivilege(jobs.length)
  , [state.currentUser, jobs])

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await jobsResource.index()
        setJobs(response.data)
      } finally {
        setLoadingJobs(false)
      }
    }
    const fetchFavorites = async () => {
      try {
        const response = await expertsResource.favorites()
        setFavorites(response.data)
      } finally {
        setLoadingFavorites(false)
      }
    }
    fetchJobs()
    fetchFavorites()
  }, [])

  useLayoutEffect(() => {
    // Widths based on bootstrap and the column widths
    // defined in job_tiles.jsx
    function updateVisibleJobCount() {
      if (window.innerWidth >= 992) { setVisibleJobCount(4) }
      else if (window.innerWidth >= 768) { setVisibleJobCount(3) }
      else if (window.innerWidth >= 576) { setVisibleJobCount(2) }
      else { setVisibleJobCount(1) }
    }

    window.addEventListener('resize', updateVisibleJobCount);
    updateVisibleJobCount();

    return () => window.removeEventListener("resize", updateVisibleJobCount);
  }, [])

  const activeJobs = jobs && jobs.filter(j => ['created', 'active'].includes(j.status))
  const archivedJobs = jobs && jobs.filter(j => ['closed', 'archived'].includes(j.status))
  const loading = loadingJobs || loadingFavorites
  const visibleJobs = activeJobs && activeJobs.slice(0, visibleJobCount);
  const overflowJobs = activeJobs && activeJobs.slice(visibleJobCount);
  const toggleAccordion = useAccordionToggle(
    "overflowJobs",
    () => setIsAccordionOpen(!isAccordionOpen),
  );

  const renderOverflowJobs = () => (
    overflowJobs && (
      <Accordion>
        <Accordion.Collapse eventKey="overflowJobs">
          <JobTiles allJobs={jobs} jobs={overflowJobs} history={history} />
        </Accordion.Collapse>
        <Accordion.Toggle
          eventKey="overflowJobs"
          className={customerStyles.toggleBtn}
          onClick={toggleAccordion}
        >
          { !isAccordionOpen && (
            <>
              <span className={customerStyles.toggleBtnArrowDown}>&#9662;</span>MORE
            </>
          )}
          { isAccordionOpen && (
            <>
              <span className={customerStyles.toggleBtnArrowUp}>&#9662;</span>LESS
            </>
          )}
        </Accordion.Toggle>
      </Accordion>
    )
  )

  const renderActiveJobs = () => (
    <div style={{marginTop: 15, paddingBottom: 15, borderBottom: '2px solid black'}}>
      <JobTiles allJobs={jobs} jobs={visibleJobs} history={history} />
      {renderOverflowJobs()}
    </div>
  )

  const renderArchivedJobs = () => (
    <Col className={customerStyles.archivedJobs} md={5} xs={12}>
      <p className={customerStyles.header}>
        Archived Jobs
        <span className={customerStyles.badge}>{archivedJobs && archivedJobs.length}</span>
      </p>

      {archivedJobs && archivedJobs.slice(0, 4).map((job) => {
        return (
          <div key={job.id}>
            <p className={customerStyles.archiveContent} onClick={() => history.push(routes.JOB(job.slug))}>
              <span>
                {job.title}
              </span>
              <span className={customerStyles.archiveDate}>
                {moment(job.closed_at || job.updated_at).format("MM/DD/YY")}
              </span>
            </p>
          </div>
        )
      })}

      <button className={customerStyles.moreBtn} onClick={() => history.push(routes.JOB_LIST)}>
        <span className={customerStyles.toggleBtnArrowDown}>&#9662;</span>MORE
      </button>
    </Col>
  )

  const renderIdeaReport = () => (
    <Col className={customerStyles.ideaReport} md={{span: 6, offset: 1}} xs={12}>
      <p className={customerStyles.header}>Idea Report</p>
      <Row>
        <Col xs={4}>
          <p className={customerStyles.callout}>
            {jobs.length}
          </p>
          <p className={customerStyles.content}>
            Total<br/>Jobs
          </p>
        </Col>
        <Col xs={4} style={{textAlign: "center"}}>
          <p className={customerStyles.callout}>
            {jobs.reduce((acc, job) => acc + job.idea_count, 0)}
          </p>
          <p className={customerStyles.content}>
            Total<br/>Ideas
          </p>
        </Col>
        <Col xs={4}>
          <p className={customerStyles.callout}>
          {jobs.reduce((acc, job) => acc + job.comment_count, 0)}
          </p>
          <p className={customerStyles.content}>
            Builds &amp;<br/>Riffs
          </p>
        </Col>
      </Row>
    </Col>
  )

  const renderFreelancerReport = () => (
    <Col className={customerStyles.freelancerReport} xs={12}>
      <p className={customerStyles.header}>Freelancer Report</p>
      <Row>
        <Col xs={3}>
          <p className={customerStyles.callout}>
            {jobs.reduce((acc, job) => acc + job.experts_invited, 0)}
          </p>
          <p className={customerStyles.content}>
            Freelancers<br/>Recruited
          </p>
        </Col>
        <Col xs={6} style={{textAlign: "center"}}>
          <p className={customerStyles.callout}>
            {formatCurrency(jobs.reduce((acc, job) => acc + job.payment_in_cents, 0) / 100.0, true)}
          </p>
          <p className={customerStyles.content}>
            Total<br/>Paid
          </p>
        </Col>
        <Col xs={3}>
          <p className={customerStyles.callout}>
            {jobs.reduce((acc, job) => acc + job.comment_count, 0)}
          </p>
          <p className={customerStyles.content}>
            Favorites<br/>Pool
          </p>
        </Col>
      </Row>
    </Col>
  )

  const renderFavoritePool = () => (
    <Col className={customerStyles.freelancerPool} xs={12}>
      <p className={customerStyles.header}>Previously Invited Freelancers</p>
      <Row className="justify-content-md-center">
        {favorites && favorites.map((favorite) => {
          return (
            <div key={favorite.id}>
              <ExpertCard expert={favorite} dragType="none" allowDrag={false} />
            </div>
          )
        })}
      </Row>
      <p style={{textAlign: "center"}}>
        Your previously invited freelancers will always show up at the top when recruiting freelancers.
      </p>
    </Col>
  )

  return (
    <>
      <ContentHeader text={`${state.currentUser.first_name}'s Command Center`}>
        { !(newJobPrivilege?.allowed) && <OverlayTrigger trigger={['hover','focus']} overlay={<Popover>
            <Popover.Content>{newJobPrivilege?.reason}</Popover.Content>
          </Popover>}
        >
          <button className="btn btn-primary btn-slim disabled" type="button" onClick={(e) => e.preventDefault()}>
            Start New Job
          </button>
        </OverlayTrigger>}
        {newJobPrivilege?.allowed && <button className="btn btn-primary btn-slim" type="button" onClick={() => history.push(routes.CREATE_JOB)}>
          Start New Job
        </button>}
      </ContentHeader>

      { loading && <Loading /> }
      { !loading && <>
        <ContentHeader style={{marginTop: 10, paddingBottom: 35 }} subtext={activeJobs.length.toString()} subtextLabel={'Active Jobs'}>
        </ContentHeader>
        { renderActiveJobs() }
        <Row className={customerStyles.reportStatistics}>
          { jobs && renderArchivedJobs() }
          { jobs && renderIdeaReport() }
          { jobs && renderFreelancerReport() }
          { renderFavoritePool() }
        </Row>
      </>}
    </>
  )
}

function JobListTile(props) {
  return (<>
    <div style={{width: '100%', marginBottom: 20, height: 210, paddingTop: 17, paddingLeft: 20, paddingRight: 20, border: '1px solid black', display: 'flex', flexDirection: 'column'}}>

      <div style={{fontSize: 22, fontWeight: 'bold', paddingBottom: 2, borderBottom: '2px solid black', marginBottom: 12}}>
        {props.title}
      </div>
      { !props.jobs.length && <p style={{fontStyle: 'italic'}}>You don't have any {props.title.toLowerCase()}.</p>}
        { props.jobs.length > 0 && <>
          <div style={{textAlign: 'right', fontSize: 11, opacity: 0.6, fontStyle: 'italic'}}>
            {props.launchDate ? 'Launch Date' : 'Days Left'}
          </div>
          <div style={{ overflowY: "scroll "}}>
          {props.jobs.map((job) => {
            const daysLeft = Math.max(moment(job.end_date).diff(moment(), 'days'), 0)
            const confirmed = job.expert_job.chosen && job.expert_job.expert_accepted || props.showAsConfirmed
            const launchDate = moment(job.start_date).format("MM/DD/YY")

            return (
              <a { ... props.title !== 'Pending Jobs' ? {href: routes.JOB(job.slug)} : {}} key={job.id} style={{display: 'flex', flexDirection: 'row', marginBottom: 16, textDecoration: 'none', color: 'black'}}>
                <div style={{position: 'relative', top: 11, width: 6, height: 6, minHeight: 6, minWidth: 6, borderRadius: '50%', marginRight: 5, backgroundColor: confirmed ? '#009245' : '#ED9B2F' }} />
                <Avatar record={job.organization} hideIfNoAvatar={true} className={expertStyles.organizationAvatar} />
                <div style={{fontSize: 16, fontWeight: 'bold', lineHeight: '28px', width: '100%'}}>{job.title}</div>
                <div style={{fontSize: 22, fontWeight: 'bold', lineHeight: '28px'}}>
                  {props.launchDate ? launchDate : daysLeft}
                </div>
              </a>
            )
          })}
        </div>
        </>}
    </div>
  </>)
}

function ExpertDashboard({history}) {
  const { dispatch, state } = useContext(Store)
  const [jobs, setJobs] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notificationsLoading, setNotificationsLoading] = useState(true)
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await jobsResource.index()
        setJobs(response.data)
      } finally {
        setLoading(false)
      }
    }
    fetchJobs()
    fetchNotifications(setNotifications, setNotificationsLoading)
  }, [])

  const activeJobs = jobs && jobs.filter(j => j.status === 'active' && j.expert_job.chosen )
  const pendingJobs = jobs && jobs.filter(j => j.status === 'created' && j.expert_job.expert_accepted && j.expert_job.chosen !== false )

  return (
    <>
      <div className={expertStyles.headerContainer}>
        <Avatar record={state.currentUser} className={expertStyles.avatar} />
        <div className={expertStyles.headerUserInfo}>
          <div style={{fontSize: 32, fontWeight: 'bold'}}>{state.currentUser.fullName}</div>
          <div style={{fontSize: 16, fontWeight: 'bold'}}>{state.currentUser.email}</div>
          { state.currentUser.expert_type !== 'employee' && <div style={{fontSize: 14, marginTop: 12}}>
            <strong>Bank:</strong>
            {state.currentUser.stripe_account_id && <>
              <div style={{display: 'inline-block', backgroundColor: '#009245', width: 10, height: 10, marginLeft: 12, marginRight: 5, borderRadius: 5}} />
              <span style={{color: '#009245'}}>Connected</span>
            </>}
            {!state.currentUser.stripe_account_id && <>
              <div style={{display: 'inline-block', backgroundColor: '#E95656', width: 10, height: 10, marginLeft: 12, marginRight: 5, borderRadius: 5}} />
              <span style={{color: '#E95656', fontStyle: 'italic'}}>Not Connected</span>
            </>}
          </div>}
        </div>
      </div>
      { loading && <Loading /> }
      { !loading && <Row>
        <Col lg={4} md={6} xs={12}>
          <JobListTile title="Pending Jobs" jobs={pendingJobs} launchDate={true} />
        </Col>
        <Col lg={4} md={6} xs={12}>
          <JobListTile title="Active Jobs" jobs={activeJobs} showAsConfirmed={true} />
        </Col>
        <Col lg={4} xs={12}>
          <NotificationsTile loading={notificationsLoading} notifications={notifications} />
        </Col>
        <Col xs={12} style={{marginBottom: 200}}>
          <ExistingExpertShortProfile expert={state.currentUser} layout="wide" />
        </Col>
      </Row>}
    </>
  )
}

export default function Dashboard (props) {
  const { dispatch, state } = useContext(Store)

  if (state && HistoryProvider.history && (!state.currentUser || !state.currentUser.id)) {
    pathAfterSignIn.set()
    HistoryProvider.history.push({ pathname: routes.SIGN_IN })
  }

  return (
    <Container>
      <Col className="px-0">
        <Helmet>
          <title>Dashboard | Ideasicle X</title>
        </Helmet>
        { state.currentUser.role === 'customer' && <CustomerDashboard {...props} />}
        { state.currentUser.role === 'expert' && <ExpertDashboard {...props} />}
        { !state.currentUser.role && <>
          <h2>Your account is inactive</h2>
          <p>If you believe this to be in error, please contact your company administrator or Ideasicle X support.</p>
        </>}
      </Col>
    </Container>
  )
}
