import React, { useState, useContext, useEffect } from 'react'
import Store from 'store'
import styles from './flag.module.css'
import classnames from 'classnames'
import * as routes from 'constants/routes'
import { SvgFlagIcon, SvgFlagActiveIcon } from 'components/general/icons'
import Config from 'utils/config'
import ideasResource from 'resources/ideas'

export default function IdeaFlag ({idea, job, setIdea, ...props}) {
  const [flagged, setFlagged] = useState(idea.flagged)

  if(!Config.idea_flags) {
    return null
  }

  const can_edit = job?.can_edit

  if(!can_edit) {
    return null
  }

  const toggleFlag = async () => {
    try {
      const response = await ideasResource.toggleFlag({ params: { id: idea.id }})
      idea.flagged = !idea.flagged
      setIdea(idea)
      setFlagged(idea.flagged)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={classnames([styles.flag, {active: flagged}])  } {...props} onClick={toggleFlag}>
      <SvgFlagIcon className={styles.flagIcon} />
    </div>
  )
}
